import React, { useEffect, useState } from 'react';

// Material UI imports 
import { DataGrid, GridToolbarColumnsButton, GridToolbarFilterButton } from '@mui/x-data-grid';
import { Container, Box, MenuItem, Select, InputLabel, FormControl } from '@mui/material';
import { styled } from '@mui/material/styles';


const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    '& .MuiDataGrid-columnHeader[data-field="weeklyChange"]': {
        borderRight: '2px dotted #3f51b5',
    },
    '& .MuiDataGrid-cell[data-field="weeklyChange"]': {
        borderRight: '2px dotted #3f51b5',
    },
    '& .MuiDataGrid-cell': {
        textAlign: 'center',
    },
    '& ::-webkit-scrollbar': {
        'border-top': '1px solid #e9e9ec',
        'height': '7px',
        'width': '7px'
    },
    '& ::-webkit-scrollbar-thumb:horizontal': {
        'border-radius': '15px',
        'background-color': '#6d6d6dc9',
    },
    '& ::-webkit-scrollbar-thumb:vertical': {
        'border-radius': '15px',
        'background-color': '#6d6d6dc9',
    },
    '& .MuiDataGrid-scrollbar--horizontal': {
        'display': 'block',
    },
}));

const CustomToolbar = () => {
    return (
        <Box sx={{
            p: 1, display: 'flex',
            justifyContent: 'flex-start',
        }}>
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
        </Box>
    );
};

const capitalizeFirstLetter = (string) => {
    return string?.charAt(0)?.toUpperCase() + string?.slice(1);
};


export default function WeeklyReportPreview({ data, onDownloadData, filters, loading }) {
    const [selectedDate, setSelectedDate] = useState('');

    useEffect(() => {
        onDownloadData();
        // eslint-disable-next-line
    }, [filters]);


    const jsonData = JSON.stringify(data);
    const parsedData = JSON.parse(jsonData).map((cur) => {
        const total = Object.values(cur?.trapsites || {}).reduce((pre, cur) => {
            return pre + (cur?.sum || 0)
        }, 0)
        return { ...cur, total }
    })

    const defaultDate = parsedData.length > 0 ? parsedData[parsedData.length - 1].date : '';

    useEffect(() => {
        if (defaultDate && !selectedDate) {
            setSelectedDate(defaultDate);
        }
    }, [defaultDate, selectedDate]);

    const handleDateChange = (event) => {
        setSelectedDate(event.target.value);
    };

    const selectedData = parsedData.find(entry => entry.date === selectedDate);
    // console.log("selected data", selectedData);

    const speciesSet = new Set();
    if (selectedData) {
        Object.values(selectedData.trapsites).forEach(trapsite => {
            if (trapsite.species) {
                trapsite.species.forEach(species => {
                    speciesSet.add(species.species);
                });
            }
        });
    }

    const speciesArray = Array.from(speciesSet)
    const uniqueSpecies = speciesArray.sort((a, b) => a.localeCompare(b));


    const rowsData = selectedData ? Object.keys(selectedData.trapsites).map((trapSite, index) => {
        const trapData = selectedData.trapsites[trapSite];
        const row = {
            id: index,
            trapSite: trapSite,
            sum: trapData.sum || "No Data",
            weeklyChange: trapData.sum > 0 ? trapData.weeklyChange : "No Data"
        };
        uniqueSpecies.forEach(speciesName => {
            row[speciesName] = 0;
        });


        if (trapData.species) {
            trapData.species.forEach(species => {
                if (uniqueSpecies.includes(species.species)) {
                    row[species.species] = species.count;
                }
            });
        }

        return row;
    }) : [];

    const columns = [
        { field: 'trapSite', headerName: 'Trap Site', width: 250, headerAlign: 'center' },
        { field: 'sum', headerName: 'Total_Count', width: 150, headerAlign: 'center' },
        { field: 'weeklyChange', headerName: 'Weekly Change', width: 200, headerAlign: 'center' },

        ...uniqueSpecies.map(speciesName => ({
            field: speciesName,
            headerName: capitalizeFirstLetter(speciesName),
            width: 150,
            headerAlign: 'center'
        }))
    ];

    // console.log(columns);

    return (
        <div style={{}}>
            <div>
                {/* {date && <h3>Date: {date}</h3>} */}
                <FormControl fullWidth margin="normal" sx={{ width: '250px' }}>
                    <InputLabel>Start Week</InputLabel>
                    <Select
                        value={selectedDate}
                        onChange={handleDateChange}
                    >
                        {parsedData.map(cur => (
                            <MenuItem key={cur.date} value={cur.date}>
                                {cur.date}{cur.total > 0 ? '' : ' (No Trapping)'}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>


                <div style={{ height: 400, width: '100%' }}>
                    <Container>
                        <Box sx={{ height: '500px', width: '100%', maxWidth: '100%', overflow: 'scroll', }}>
                            <div
                                style={{
                                    height: '100%',
                                    width: '100%',
                                    overflow: 'auto',
                                }}
                            >
                                <StyledDataGrid
                                    rows={rowsData}
                                    columns={columns}
                                    pageSize={rowsData.length}
                                    rowsPerPageOptions={[rowsData.length]}
                                    localeText={{ noRowsLabel: loading ? "" : "No Data" }}
                                    slots={{
                                        toolbar: CustomToolbar,
                                    }}
                                    initialState={{
                                        pagination: {
                                            paginationModel: {
                                                pageSize: 10,
                                            },
                                        },
                                        columns: {
                                            columnVisibilityModel: {
                                                empty: false,
                                                Unsure: false,
                                            },
                                        }
                                    }}
                                    disableSelectionOnClick
                                />
                            </div>
                        </Box>
                    </Container>

                </div>
            </div>
        </div>

    );

}

import React, { useContext } from "react";
import { snakeify } from "./../utils/caseConvert";
import { useAuth0 } from "./react-auth0-spa";
import axios from "axios";
import ExcelJS from 'exceljs'
//import { filtersSerializer } from "../lib/helpers";
// import handleAPIError from "../lib/api/ErrorHandler";

const baseUrlSec =
  process.env.REACT_APP_ENV !== "development" ? "https://" : "http://";
const baseURL = process.env.REACT_APP_BACKEND_ENDPOINT || "localhost:8000";
const ACAO =
  process.env.REACT_APP_ENV !== "development"
    ? process.env.REACT_APP_ENV === "staging"
      ? "https://mos-id-backend-staging.vectech.io"
      : "https://mos-id-backend.vectech.io"
    : "*";

const apiConfig = {
  mainAPI: {
    baseURL: baseUrlSec + baseURL,
  }
};

const organizationAPI = process.env.REACT_APP_ORGANIZATION_API;

const WebServerContext = React.createContext();
const useWebServer = () => useContext(WebServerContext);

const WebServerProvider = ({ children }) => {
  const { getTokenSilently, getTokenWithPopup } = useAuth0();

  const getToken = async (ignoreCache = false) => {
    try {
      return await getTokenSilently({ ignoreCache: ignoreCache });
    } catch (e) {
      try {
        return await getTokenWithPopup();
      } catch (e) {
      }
    }
  };

  const createApi = ({ baseURL, token }) =>
    axios.create({
      baseURL,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": ACAO,
      },
    });

  const createTokenizedAPI = async ({ devPort }) => {
    const token = await getToken();
    return createApi({
      baseURL:
        devPort && process.env.REACT_APP_ENV === "development"
          ? String(apiConfig.mainAPI.baseURL).slice(0, -4) + devPort
          : apiConfig.mainAPI.baseURL,
      token,
    });
  };

  const sendRequest = (method) => async ({ url, data, params, devPort = null, isOrganizationAPI }) => {
    const api = isOrganizationAPI ? await createOrganizationMsAPI() : await createTokenizedAPI({ devPort });
    const snakedParams = snakeify(params, true);
    const response = await api.request({
      url,
      method,
      data: snakeify(data),
      params: snakedParams,
    });
    return response;
  };

  const getGPSLocationFromAddress = async (address) => {
    const resp = await axios.get(
      `https://nominatim.openstreetmap.org/search?format=json&q='+${address}`
    );
    return resp;
  };

  const getAddressFromGPSLocation = async (lat, lng) => {
    try {
      const resp = await axios.get(
        `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${lat}&lon=${lng}`
      );
      return resp;
    } catch (err) {
      console.log(err)
    }
  };

  const wsReconnectPingServer = async ({ timeout = 0 }) => {
    const token = await getToken();
    const api = createApi({
      baseURL: getWebsocketBaseUrl(),
      token,
    });
    return api.get("/health", { timeout });
  };

  const getWebsocketUrl = () => {
    // const protocol = baseUrlSec === 'https://' ? 'wss://' : 'ws://';
    if (process.env.REACT_APP_ENV === "development") {
      return `${baseUrlSec}localhost:8889`;
    }
    return `${baseUrlSec}${baseURL}`;
  };

  const getWebsocketBaseUrl = () => {
    if (process.env.REACT_APP_ENV === "development") {
      return `${baseUrlSec}localhost:8889`;
    }
    return `${baseUrlSec}${baseURL}`;
  };

  const createOrganizationMsAPI = async () => {
    const token = await getToken();
    return createApi({
      baseURL: organizationAPI,
      token,
    });
  };

  const downloadOrganizationData = async ({ filters, isDownload = false }) => {
    const api = await createOrganizationMsAPI()
    const res = await api.request({
      url: `/organization/data`,
      method: "post",
      data: filters || {},
      isDownload,
      responseType: "json",
    });
    return res;
  };

  const downloadOrganizationDataWithRawData = async ({ filters, isDownload = false }) => {
    const api = await createOrganizationMsAPI()
    const res = await api.request({
      url: `/organization/data`,
      method: "post",
      data: filters || {},
      responseType: "json",
    });
    try {
      const response = await fetch(res?.data?.url);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const blob = await response.blob();
      const arrayBuffer = await blob.arrayBuffer();

      const workbook = new ExcelJS.Workbook();
      await workbook.xlsx.load(arrayBuffer);

      const worksheet = workbook.worksheets[0];
      const rows = [];
      let headers = [];

      worksheet.eachRow((row, rowNumber) => {
        if (rowNumber === 1) {
          //first row is the header row
          headers = row.values.slice(1); // Skip the first empty value
        } else {
          const rowObject = {};
          headers.forEach((header, index) => {
            rowObject[header] = row.values[index + 1];
          });
          rows.push(rowObject);
        }
      });
      return { data: rows }

    } catch (error) {
      console.error('Error fetching JSON from S3:', error);
    }
  };

  const getOrganizationSummaryData = async ({ filters }) => {
    const api = await createOrganizationMsAPI()
    const res = await api.request({
      url: `/organization/summary`,
      method: "post",
      data: filters || {},
    });
    return res?.data?.body;
  };

  const downloadOrganizationDataWithAbundance = async ({ filters }) => {
    const api = await createOrganizationMsAPI()
    const res = await api.request({
      url: `/organization/data/abundance`,
      method: "post",
      data: filters || {},
      responseType: "json",
    });
    return res;
  };


  const downloadOrganizationDataWithWeekly = async ({ filters }) => {
    const api = await createOrganizationMsAPI()
    const res = await api.request({
      url: `/organization/data/weeklyreport`,
      method: "post",
      data: filters || {},
      responseType: "json",
    });
    console.log("weeklyreport function", res);
    return res;
  };


  const downloadSpeciesLineChart = async ({ filters }) => {
    const api = await createOrganizationMsAPI()
    const res = await api.request({
      url: `/organization/data/linechart`,
      method: "post",
      data: filters || null,
      responseType: "json",
    });
    return res;
  };

  const downloadMonthlyImaging = async ({ filters }) => {
    const api = await createOrganizationMsAPI()
    const res = await api.request({
      url: `/organization/data/monthlyImaging`,
      method: "post",
      data: filters || null,
      responseType: "json",
    });
    return res;
  };


  return (
    <WebServerContext.Provider
      value={{
        sendRequest,
        getGPSLocationFromAddress,
        getAddressFromGPSLocation,
        getWebsocketUrl,
        wsReconnectPingServer,
        getToken,
        downloadOrganizationData,
        downloadOrganizationDataWithAbundance,
        downloadOrganizationDataWithRawData,
        downloadOrganizationDataWithWeekly,
        downloadSpeciesLineChart,
        getOrganizationSummaryData,
        downloadMonthlyImaging
      }}
    >
      {children}
    </WebServerContext.Provider>
  );
};

export default WebServerProvider;
export { useWebServer };

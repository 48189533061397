import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { useWebServer } from "../../../../providers";
import { useHistory } from "react-router-dom";
import ROUTES from "../../../AppRouter/routes";
import TrayViewer from "./TrayViewer";
import {
  startCapture,
  flipCapture,
  setMetadataErrors,
  finishCapture,
  checkOnCapture,
  fetchTray,
  flipTray,
  deleteTray,
  retakeCapture,
  uploadTrayMetadata,
  updateTrayFromMetadata,
  jumpSpecimen,
} from "../../../../actions";
import CircularProgress from "@mui/material/CircularProgress";
import trayMetadataSubmit from "./../../../Forms/TrayMetadataSubmit";
import { orientationFlipped } from "../../../../utils/orientation";
import { rx, ry } from "../../../../utils/const";
import { capitalize } from "../../../../utils/caseConvert";
import PreviousNextContainer from "./PreviousNextContainer/index.js";
import ConfirmDialog from "../../../ConfirmDialog";
import { flatten } from 'lodash'

const Index = ({
  startCapture,
  flipCapture,
  retakeCapture,
  finishCapture,
  checkOnCapture,
  fetchTray,
  uploadTrayMetadata,
  flipTray,
  setMetadataErrors,
  deleteTray,
  submit,
  otherSideDone,
  updateTrayFromMetadata,
  selectedDevice,
  traySide,
  capturing,
  toolId,
  trapSites,
  trayValid,
  specimenOnDisplay,
  trapTypeAutoCompleteOptions,
  trayId,
  createdOn,
  trayImage,
  specimens,
  disableCapturing,
  erroredTray,
  hasMetadataErrors,
  jumpSpecimen,
  isValid,
  timeZone,
  specList,
  tagsOptions,
  ...otherProps
}) => {
  const { initialValues } = otherProps;
  const { sendRequest } = useWebServer();
  const history = useHistory();
  const [trayLoaded, setTrayLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [metadataErrorDialog, setMetadataErrorDialog] = useState(false);
  const [metadata, setMetadata] = useState(initialValues);
  const [goodToSubmit, setGoodToSubmit] = useState(false);
  const [captureBothSideModal, setCaptureBothSideModal] = useState(false);
  const [savedHistoryCaptureBothSide, setSavedHistoryCaptureBothSide] =
    useState("");

  useEffect(() => {
    const deleteErroredTray = async () => {
      if (erroredTray > 0) {
        finishCapture(toolId, erroredTray);
      }
      history.push(ROUTES.traysTab);
    };
    if (erroredTray || erroredTray === 0) {
      deleteErroredTray();
    }
  }, [erroredTray, finishCapture, history, toolId]);

  const onChangeMetadata = (values, isChanged) => {
    setMetadata({ ...metadata, ...values });
    if (goodToSubmit) {
      trayMetadataSubmit(
        { ...metadata, ...values },
        {
          uploadTrayMetadata,
          updateTrayFromMetadata,
          selectedDevice,
          trayId,
          createdOn,
          trapSites,
          specList,
        },
        isChanged
      );
    }
  };

  useEffect(() => {
    // if no device is selected, go back to the traysTab
    if (!selectedDevice) {
      history.push(ROUTES.traysTab);
    }
  }, [history, selectedDevice]);

  useEffect(() => {
    let intervalId = null;
    if (loading || capturing) {
      intervalId = setInterval(() => {
        checkOnCapture(trayId, { ignore_errors: true }, sendRequest);
      }, 5000);
    }
    return () => (intervalId ? clearInterval(intervalId) : null);
  }, [trayId, loading, capturing, traySide, checkOnCapture, sendRequest]);

  useEffect(() => {
    setGoodToSubmit(false);
    if (!trayId) {
      setTrayLoaded(false);
    } else {
      //the trayId is changed from other, the metadata should be refresh and reinit the tray from
      fetchTray(trayId, { ignore_errors: true }, sendRequest).then(() => {
        // initialize(initialValues);
        setMetadata(initialValues);
        setTrayLoaded(true);
        setGoodToSubmit(true);
      });
      return () => {
        if (trayId && !trayValid) {
          //const trapSitesMap = _.mapKeys(trapSites, "id");
          // const trapSite =
          //   metadata?.trapSiteId === null || metadata?.trapSiteId === ""
          //     ? null
          //     : trapSitesMap?.[metadata?.trapSiteId];
          finishCapture(toolId, trayId);
        }
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trayId]);

  useEffect(() => {
    if (!disableCapturing && trayValid) {
      setMetadata({ ...metadata, valid: trayValid });
      // change("valid", trayValid);
      updateTrayFromMetadata(trayId, selectedDevice, metadata, null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trayValid, disableCapturing, selectedDevice, trayId]);

  const handleRetakeTray = async () => {
    retakeCapture(toolId, trayId, traySide);
  };

  const handleCaptureOtherSide = async () => {
    flipCapture(toolId, trayId, orientationFlipped(traySide));
  };

  const handleSwitchImage = async () => {
    flipTray();
  };

  const shouldDisableCaptureOtherSide = () => {
    return disableCapturing || loading || capturing || otherSideDone;
  };

  const shouldDisableSwitchImageButton = () => {
    return loading || capturing || !otherSideDone;
  };

  const shouldDisableRetakeSide = () => {
    return disableCapturing || loading || capturing;
  };

  const shouldDisableFinish = () => {
    return loading || capturing;
  };

  const handleDeleteTray = async () => {
    setLoading(true);
    updateTrayFromMetadata(
      trayId,
      selectedDevice,
      { metadata, deleted: true },
      null
    );
    await deleteTray(trayId, sendRequest);
    setLoading(false);
    history.goBack();
  };

  const handleFinish = () => {
    if (hasMetadataErrors) {
      setMetadataErrorDialog(true);
      setMetadataErrors(true);
    } else {
      if (!otherSideDone && history.location.pathname === ROUTES.captureTray) {
        setCaptureBothSideModal(true);
        setSavedHistoryCaptureBothSide(ROUTES.traysTab);
      } else {
        history.push(ROUTES.traysTab);
      }
    }
  };

  const handleSpecimenJump = (i) => {
    if (isValid) {
      jumpSpecimen(i);
    } else {
      setMetadataErrors(true);
    }
  };

  return trayLoaded && !erroredTray ? (
    <>
      <ConfirmDialog
        title={`You have not captured the other side of the tray`}
        open={captureBothSideModal}
        setOpen={setCaptureBothSideModal}
        onConfirm={() => {
          history.push(savedHistoryCaptureBothSide);
        }}
        children={
          'If you want to capture the other side, click "Cancel" and then click "Capture Other Side"'
        }
      ></ConfirmDialog>
      <PreviousNextContainer
        trayValid={trayValid}
        setSavedHistoryCaptureBothSide={setSavedHistoryCaptureBothSide}
        setCaptureBothSideModal={setCaptureBothSideModal}
        otherSideDone={otherSideDone}
      >
        <TrayViewer
          loading={loading}
          trayImage={trayImage}
          specimens={specimens}
          handleRetakeTray={handleRetakeTray}
          handleCaptureOtherSide={handleCaptureOtherSide}
          handleDeleteTray={handleDeleteTray}
          handleSwitchImage={handleSwitchImage}
          disableRetakeSide={shouldDisableRetakeSide()}
          disableSwitchButton={shouldDisableSwitchImageButton()}
          disableCaptureOtherSide={shouldDisableCaptureOtherSide()}
          disableFinish={shouldDisableFinish()}
          handleFinish={handleFinish}
          trapSites={trapSites}
          trayId={trayId}
          traySideMsg={traySide ? ` (${capitalize(traySide)})` : ""}
          trayCaptureTimeStamp={createdOn}
          handleChangeMetadata={onChangeMetadata}
          specimenOnDisplay={specimenOnDisplay}
          metadataErrorDialogOpts={{
            open: metadataErrorDialog,
            setOpen: setMetadataErrorDialog,
            onConfirm: () => history.goBack(),
          }}
          trapTypeAutoCompleteOptions={trapTypeAutoCompleteOptions}
          handleSpecimenJump={handleSpecimenJump}
          {...otherProps}
          pathname={history.location.pathname}
          timeZone={timeZone}
          tagList={metadata.tags}
          metadata={metadata}
          tagsOptions={tagsOptions}
        />
      </PreviousNextContainer>
    </>
  ) : (
    <div className={"flex flex-1 flex-col"}>
      <CircularProgress size={300} className={"z-50 mx-auto my-auto p-4"} />
    </div>
  );
};

const scaleBbox = (bbox) => {
  if (Array.isArray(bbox) && bbox.length === 4) {
    const [x1, y1, x2, y2] = bbox;
    return [x1 / rx, y1 / ry, x2 / rx, y2 / ry];
  }
};

const parseEmptySpp = (spp) => {
  // console.log(JSON.stringify(spp, null, 4));
  const algEmpty = spp?.empty;
  if (algEmpty) {
    if (algEmpty === "dirty") {
      return {
        ...spp,
        bboxGenus: "dirty",
        bboxSpecies: "cell",
      };
    } else if (algEmpty === "empty") {
      return {
        ...spp,
        bboxGenus: "empty",
        bboxSpecies: "cell",
      };
    }
  }
  return {
    bboxGenus: spp?.genus,
    bboxSpecies: spp?.species,
  };
};

export const mapStateToProps = (state) => {
  const { selectedDevice } = state.devices;
  if (!selectedDevice) return { selectedDevice };
  const {
    [selectedDevice]: { id: toolId, vectorType },
  } = state.devices;
  const {
    tray,
    capturing,
    disableCapturing,
    trayValid,
    specimenOnDisplay,
    otherSideDone,
    erroredTray,
  } = state.capture;
  let { traySide } = state.capture;
  if (erroredTray) {
    return { selectedDevice, erroredTray };
  }
  if (!traySide) {
    traySide = "front";
  }
  if (tray && traySide) {
    const {
      id: trayId,
      displayName: trayDisplayName,
      specimens: specList,
      createdOn,
      [`${traySide}Img`]: trayImage,
      [`bboxes${capitalize(traySide)}`]: bboxes,
    } = tray;

    let userAlgConfidence = 0
    if (state.capture.tray.algConfidenceThreshold > 0) {
      userAlgConfidence = state.capture.tray.algConfidenceThreshold
    } else {
      if (vectorType?.startsWith('m')) {
        userAlgConfidence = state?.organization?.algorithmConfidence?.["mosquito"]
      } else {
        userAlgConfidence = state?.organization?.algorithmConfidence?.["tick"]
      }
    }
    if (userAlgConfidence > 1) {
      userAlgConfidence = userAlgConfidence / 100
    }
    let specimens = traySide
      ? Object.values(specList || {}).map((specimen) => {
        let algorithmId = specimen?.[traySide]?.algorithmId;
        let genusSpecies = parseEmptySpp(specimen?.[traySide]?.algorithmId);
        let algRegional =
          specimen?.[traySide]?.algorithmId?.regional === undefined
            ? true
            : specimen?.[traySide]?.algorithmId?.regional;
        if (
          !(
            algorithmId?.empty === "empty" || algorithmId?.empty === "dirty"
          ) &&
          (algorithmId?.speciesConfidence < userAlgConfidence || !algRegional)
        ) {
          genusSpecies["bboxGenus"] = "Unknown";
          genusSpecies["bboxSpecies"] = "Spp";
        }
        return {
          ..._.omit(specimen, ["front", "back"]),
          imgPath: specimen?.[traySide]?.imgPath,
          bbox: scaleBbox(
            Array.isArray(bboxes) &&
              bboxes.length > 0 &&
              (specimen?.num || specimen?.num === 0)
              ? bboxes[specimen.num]
              : specimen?.[traySide]?.bbox
          ),
          ...(specimen?.[traySide]?.algorithmId ? genusSpecies : {}),
        };
      })
      : null;

    const trapSites = _.isPlainObject(state?.trapSites)
      ? Object.values(state.trapSites)
        .map((trapSite) => _.pick(trapSite, ["id", "displayName"]))
        .filter((trapSite) => !!trapSite?.id)
      : [];

    let initialValues = {
      ..._.pick(
        tray,
        "trapType",
        "notes",
        "trapSiteId",
        "captureDate",
        "tags",
        "id"
      ),
    };

    //process capture date
    if (
      typeof initialValues?.captureDate === "string" &&
      initialValues?.captureDate !== ""
    ) {
      let curDate = new Date(initialValues?.captureDate);
      let year = curDate.getUTCFullYear();
      let monthIndex = curDate.getUTCMonth();
      let day = curDate.getUTCDate();
      initialValues["captureDate"] = new Date(year, monthIndex, day);
    }
    if (!initialValues?.captureDate) {
      initialValues["captureDate"] = null;
    }
    if (!initialValues?.tags) {
      initialValues["tags"] = [];
    }


    //get all traptypes from the current trays
    let trayTypesAll = Object.values(state?.devices)?.reduce((accumulator, cur) => {
      if (cur?.trays) {
        let res = Object.values(cur?.trays)?.reduce((acc, curTray) => {
          if (curTray.id === trayId) {
            return acc
          }
          if (curTray?.trapType) {
            return [...acc, curTray?.trapType]
          }
          return acc
        }, [])
        return [...accumulator, ...res]
      }
      return accumulator
    }, [])

    //get all tags from the current trays
    let tagsAll = Object.values(state?.devices)?.reduce((accumulator, cur) => {
      if (cur?.trays) {
        let res = Object.values(cur?.trays)?.reduce((acc, curTray) => {
          if (curTray.id === trayId) {
            return acc
          }
          if (curTray?.tags) {
            return [...acc, curTray?.tags]
          }
          return acc
        }, [])
        return [...accumulator, ...res]
      }
      return accumulator
    }, [])


    const { trapTypes: contributedTrapTypes = [], tags: tagsOptions = [] } = state.organization;
    const { timeZone } = state.user;
    const result = {
      selectedDevice,
      traySide,
      capturing,
      disableCapturing,
      otherSideDone,
      trayValid,
      trayId,
      trayDisplayName: parseInt(trayDisplayName)
        ? `TRAY ${trayDisplayName}`
        : trayDisplayName,
      createdOn,
      trayImage,
      specimens,
      specList,
      trapSites,
      initialValues,
      specimenOnDisplay,
      timeZone,
      // isValid: isValid("SpecimenCapture")(state) || true,
      isValid: true,
      hasMetadataErrors:
        state.form?.SpecimenCapture?.submitFailed ||
        state.form?.TrayCapture?.submitFailed,
      trapTypeAutoCompleteOptions: Array.from(new Set([...trayTypesAll, ...contributedTrapTypes])).map((x) => `${x}`),
      tagsOptions: Array.from(new Set([...flatten(tagsAll), ...tagsOptions]))
    };
    return result;
  }
  return { selectedDevice, toolId };
};

export default connect(mapStateToProps, {
  startCapture,
  flipCapture,
  finishCapture,
  checkOnCapture,
  retakeCapture,
  fetchTray,
  flipTray,
  deleteTray,
  uploadTrayMetadata,
  updateTrayFromMetadata,
  setMetadataErrors,
  jumpSpecimen,
})(Index);
